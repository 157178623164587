import React from 'react'
import loadable from '@loadable/component'
import MetaData from '../components/MetaData'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import { Section } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Hero from '../sections/common/Hero'
import LsCTA from '../components/LsCTA'
import setLanguage from '../helpers/languageConfig'


const SupportedTable = loadable(() =>
  import('../components/Table/SupportedTable')
)

const SupportedExchs = props => {
  const { t, ready } = useTranslation(['supportedExchanges', 'tables'], { useSuspense: false })
  const lang = setLanguage();
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const cta = {
    title: ready && t('connectBtn'),
    content: ready && t('connectText'),
    btnText: ready &&  t('connectBtn'),
    btnLink: process.env.LS_SIGNUP,
    externalLink: true,
    icon: false,
  }

  return ready && (
    <>
      <MetaData page="supportedExchanges" />
      <PageWrapper headerDark footerDark>
        <Hero
          className="pb-2 mt-5"
          bg={userDarkMode ? 'black' : 'bg'}
          title={t('title')}
        >
          {t('subtitle')}
        </Hero>
        <Section bg={userDarkMode ? 'black' : 'bg'} pt={[0, null, null, 0]} pb={[40, null, null, 40]}>
          <Container>
            <Row>
              <Col lg="12">
                <LsCTA userDarkMode={userDarkMode} cta={cta} />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg={userDarkMode ? 'black' : 'bg'} pt={[0, null, null, 0]}>
          <Container>
            <Row>
              <Col lg="12">
                <SupportedTable />
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default SupportedExchs
